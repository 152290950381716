// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
@use "../node_modules/bulma/bulma.scss";

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-primary: mat.define-palette(mat.$indigo-palette);
$admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat.define-light-theme((color: (primary: $admin-primary,
        accent: $admin-accent,
        warn: $admin-warn,
      ),
      // typography: mat.define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-theme);

@import "../node_modules/bulma/sass/utilities/initial-variables";

@import "../node_modules/bulma-checkbox/css/main.min.css";
@import "../node_modules/@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css";

/* You can add global styles to this file, and also import other style files */
.navbar-item {
  font-size: 1.05rem;
}

p {
  font-size: 1.05rem;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  mask-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0)),
    -webkit-linear-gradient(to right, rgba(0, 0, 0, 1) 90%, transparent),
    -webkit-linear-gradient(to left, rgba(0, 0, 0, 1) 90%, transparent),
    -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent),
    -webkit-radial-gradient(farthest-side at center top, rgba(0, 0, 0, 1), rgba(0, 0, 0, .4) 50%, transparent);
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0)),
    linear-gradient(to right, rgba(0, 0, 0, 1) 90%, transparent),
    linear-gradient(to left, rgba(0, 0, 0, 1) 90%, transparent),
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent),
    radial-gradient(farthest-side at center top, rgba(0, 0, 0, 1), rgba(0, 0, 0, .4) 50%, transparent);
  -webkit-mask-composite: intersect;
  mask-composite: intersect;
}

.max-width-384 {
  max-width: 384px;
}

.transparent {
  background-color: transparent !important;
}

:root {
  --bulma-text: #fff;
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }